// rootlyticsConfig.js;

// prettier-ignore
// Align by RegEx (extension): value|label|teamId|reportTypes
const REPORTS = [
  {    value: 'beet-revenue',          label: 'Beet Revenue',            teamId: '',   reportType: 'agency' },
  {    value: 'team-golden-revenue',   label: 'Team Golden Revenue',     teamId: '5',  reportType: 'client_team' },
  {    value: 'team-ruby-queen',       label: 'Team Ruby Queen Revenue', teamId: '6',  reportType: 'client_team' },
  {    value: 'team-wonder',           label: 'Team Wonder Revenue',     teamId: '7',  reportType: 'client_team' },
  {    value: 'team-avalanche',        label: 'Team Avalanche Revenue',  teamId: '9',  reportType: 'client_team' },
  {    value: 'ct-earth-revenue',      label: 'CT Earth Revenue',        teamId: '1',  reportType: 'channel_team' },
  {    value: 'ct-cultivator-revenue', label: 'CT Cultivator Revenue',   teamId: '2',  reportType: 'channel_team' },
  {    value: 'ct-nutrition-revenue',  label: 'CT Nutrition Revenue',    teamId: '3',  reportType: 'channel_team' },
  {    value: 'ct-butterfly-revenue',  label: 'CT Butterfly Revenue',    teamId: '4',  reportType: 'channel_team' },
  {    value: 'ct-bee-revenue',        label: 'CT Bee Revenue',          teamId: '10', reportType: 'channel_team' },
  // { value: 'st-biotech-revenue',    label: 'ST Biotech Revenue',      teamId: '8',  reportType: 'team' },
];

// Align by RegEx (extension): value|label|itemsName|reportTypes|chart|dataMultiple|excludeColumns|excludeFromChart|excludeSubColumns|excludeIndividualSubColumns
const REPORT_VIEWS = [
  {
    value: 'organisation',
    label: 'Total',
    itemsName: 'Agency',
    reportTypes: ['agency', 'client_team', 'channel_team'],
    chart: true,
    dataMultiple: false,
    excludeColumns: [],
    excludeFromChart: [],
    excludeSubColumns: [],
    excludeIndividualSubColumns: [],
  },
  {
    value: 'consulting',
    label: 'Consulting',
    itemsName: 'Agency',
    reportTypes: ['agency', 'client_team', 'channel_team'],
    chart: true,
    dataMultiple: false,
    excludeColumns: [],
    excludeFromChart: [],
    excludeSubColumns: [],
    excludeIndividualSubColumns: [],
  },
  {
    value: 'tools',
    label: 'Tools & Licenses',
    itemsName: 'Tool/License',
    reportTypes: ['agency', 'client_team', 'channel_team'],
    chart: false,
    dataMultiple: true,
    excludeColumns: ['reported', 'potential'],
    excludeFromChart: [],
    excludeSubColumns: ['hours', 'rate'],
    excludeIndividualSubColumns: [],
  },
  {
    value: 'client_team',
    label: 'Teams',
    itemsName: 'Team',
    reportTypes: ['agency'],
    chart: true,
    dataMultiple: true,
    excludeColumns: [],
    excludeFromChart: [],
    excludeSubColumns: [],
    excludeIndividualSubColumns: [],
  },
  {
    value: 'channel_team',
    label: 'Channel Teams',
    itemsName: 'Team',
    reportTypes: ['agency'],
    chart: true,
    dataMultiple: true,
    excludeColumns: [],
    excludeFromChart: [],
    excludeSubColumns: [],
    excludeIndividualSubColumns: [],
  },
  {
    value: 'channel',
    label: 'Channels',
    itemsName: 'Channel',
    reportTypes: ['agency', 'client_team', 'channel_team'],
    chart: false,
    dataMultiple: true,
    excludeColumns: ['goal', 'potential'],
    excludeFromChart: [],
    excludeSubColumns: ['vs', 'vs (h)'],
    excludeIndividualSubColumns: [],
  },
  {
    value: 'service',
    label: 'Services',
    itemsName: 'Service',
    reportTypes: ['agency', 'client_team', 'channel_team'],
    chart: false,
    dataMultiple: true,
    excludeColumns: ['goal', 'potential'],
    excludeFromChart: [],
    excludeSubColumns: ['vs', 'vs (h)'],
    excludeIndividualSubColumns: [],
  },
  {
    value: 'consultant',
    label: 'Consultants',
    itemsName: 'Consultant',
    reportTypes: ['agency', 'client_team', 'channel_team'],
    chart: false,
    dataMultiple: true,
    excludeColumns: ['goal'],
    excludeFromChart: [],
    excludeSubColumns: [],
    excludeIndividualSubColumns: ['billable_vs_goal', 'billable_hours_vs_goal_hours'],
  },
  {
    value: 'client',
    label: 'Clients',
    itemsName: 'Client',
    reportTypes: ['agency', 'client_team', 'channel_team'],
    chart: false,
    dataMultiple: true,
    excludeColumns: ['goal', 'potential'],
    excludeFromChart: [],
    excludeSubColumns: ['vs', 'vs (h)'],
    excludeIndividualSubColumns: [],
  },
  {
    value: 'clients_billable',
    label: 'Clients Billable',
    itemsName: 'Client',
    reportTypes: ['agency', 'client_team', 'channel_team'],
    chart: false,
    dataMultiple: true,
    excludeColumns: [],
    excludeFromChart: [],
    excludeSubColumns: [],
    excludeIndividualSubColumns: [],
  },
];

// Table -----------------------------------------------------------------------
// Initial state of visible columns
const INITIAL_VISIBLE_COLUMNS = {
  goal: true,
  potential: false,
  billable: true,
  budgeted: false,
  reported: true,
};

// Initial state of visible sub-columns
const INITIAL_VISIBLE_SUB_COLUMNS = {
  amount: true,
  hours: false,
  rate: false,
  mom: true,
  yoy: true,
  vs: true,
  'vs (h)': false,
};

// Chart -----------------------------------------------------------------------
// Default chart options for various data types
const DEFAULT_CHART_OPTIONS = {
  dot: 'goal_amount',
  bar: 'billable_amount',
  line: 'potential_amount',
};

// Exports for external usage
export { REPORTS, REPORT_VIEWS, INITIAL_VISIBLE_COLUMNS, INITIAL_VISIBLE_SUB_COLUMNS, DEFAULT_CHART_OPTIONS };
