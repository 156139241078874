// IMPORTS
import { REPORT_VIEWS, REPORTS } from 'src/sections/@dashboard/rootlytics/rootlyticsConfig';

// Check if client_team or channel_team report type
export const checkIsTeamReport = (reportType) => reportType === 'client_team' || reportType === 'channel_team';

/**
 * Utility function to dynamically assign the correct ID based on report type and sub-report.
 *
 * @param {string} reportView - The sub-report type (e.g., 'tools', 'client_team').
 * @param {string} reportType - The type of the report (e.g., 'team', 'agency').
 * @param {number|null} teamId - The ID of the team, if applicable.
 *
 * @returns {Object} An object containing the correct ID mappings based on the report type.
 */
export const getDynamicId = (reportView, reportType, teamId) => {
  const idMappings = {
    tools: { license_id: 0 },
    client_team: { team_id: teamId || 0 },
    channel_team: { channel_team_id: 0 },
    channel: { channel_id: 0 },
    service: { service_id: 0 },
    client: { client_id: 0 },
    consultant: { consultant_id: 0 },
    clients_billable: { team_id: teamId || 0 },
  };

  return checkIsTeamReport(reportType) ? { team_id: teamId || 0 } : idMappings[reportView] || {};
};

/**
 * Utility function to check if a reportView exists for a given reportType.
 *
 * @param {string} reportView - The sub-report type.
 * @param {string} reportType - The type of the report.
 *
 * @returns {boolean} True if the reportView exists for the reportType.
 */
export const reportViewExists = (reportView, reportType) => {
  return REPORT_VIEWS.some((item) => item.value === reportView && item.reportTypes.includes(reportType));
};

/**
 * Utility function to find the first valid sub-report for a given reportType.
 *
 * @param {string} reportType - The type of the report.
 *
 * @returns {string} The first valid sub-report for the reportType or an empty string.
 */
export const firstValidReportView = (reportType) => {
  return REPORT_VIEWS.find((item) => item.reportTypes.includes(reportType))?.value || '';
};

/**
 * Generates a cache key based on the parameters and ensures the reportView is valid.
 *
 * @param {string} report - The report type.
 * @param {string} reportView - The sub-report type.
 * @param {string} reportType - The type of the report.
 * @param {string} periodType - The period type ('month' or 'year').
 * @param {string} periodYear - The year of the period.
 * @param {string} periodMonth - The month of the period (if periodType is 'month').
 * @param {number|null} teamId - The ID of the team, if applicable.
 *
 * @returns {string} A stringified cache key used for caching data.
 */
export const generateCacheKey = (report, reportView, reportType, periodType, periodYear, periodMonth, teamId) => {
  let startDate, endDate;

  if (periodType === 'month') {
    startDate = `${periodYear}-${periodMonth}-01`;
    const month = parseInt(periodMonth, 10);
    const date = new Date(periodYear, month, 0);
    const lastDay = date.getDate();
    endDate = `${periodYear}-${periodMonth}-${lastDay}`;
  } else {
    startDate = `${periodYear}-01-01`;
    endDate = `${periodYear}-12-31`;
  }

  const validReportView = reportViewExists(reportView, reportType) ? reportView : firstValidReportView(reportType);

  const isTeamReport = checkIsTeamReport(reportType);

  const type = isTeamReport ? reportType : validReportView;

  const subType = isTeamReport ? (validReportView === 'organisation' ? '' : validReportView) : validReportView;

  const dynamicParams = getDynamicId(subType, reportType, teamId);

  return JSON.stringify({
    report,
    reportView: subType,
    reportType,
    type,
    startDate,
    endDate,
    periodType,
    periodYear,
    periodMonth,
    ...dynamicParams,
    isClientOverview: reportView === 'clients_billable',
  });
};

/**
 * Extracts parameters from the cacheKey.
 *
 * @param {string} cacheKey - The cache key string.
 *
 * @returns {Object} The extracted parameters for API requests.
 */
export const extractParamsFromCacheKey = (cacheKey) => {
  const {
    report,
    reportType,
    reportView,
    startDate,
    endDate,
    periodType,
    periodYear,
    periodMonth,
    isClientOverview,
    ...dynamicParams
  } = JSON.parse(cacheKey);

  return {
    reportType,
    reportView,
    startDate,
    endDate,
    periodType,
    periodYear,
    periodMonth,
    isClientOverview,
    dynamicParams,
  };
};

/**
 * Filters data by excluding specific fields.
 *
 * @param {Array} data - The dataset to be filtered.
 * @param {Array} excludeFields - An array of field names to exclude from each data object.
 *
 * @returns {Array} A new dataset with the specified fields removed from each object.
 */
export const filterDataByExclusion = (data, excludeFields) => {
  if (!excludeFields || excludeFields.length === 0) {
    return data;
  }

  return data.map((item) => {
    const filteredItem = { ...item };
    excludeFields.forEach((field) => {
      delete filteredItem[field];
    });
    return filteredItem;
  });
};

// EXPORTS ---------------------------------------------------------------------
export default {
  getDynamicId,
  reportViewExists,
  firstValidReportView,
  generateCacheKey,
  extractParamsFromCacheKey,
  filterDataByExclusion,
};
