// DOCUMENTATION ---------------------------------------------------------------
// Roles for permission:
// 'SUPER_ADMIN',
// 'BOARD_OF_DIRECTORS',
// 'TEAM_LEAD',
// 'CHANNEL_TEAM_LEAD',
// 'ADMIN',
// 'ECONOMY',
// 'HUMAN_RESOURCES',
// 'CLIENT_MANAGER',
// 'CONSULTANT',
// 'LIA',
// 'SUB_CONSULTANT',

//ANCHOR Breakpoints -----------------------------------------------------------
// The day of month that is the breakpoint for the invoicing period
export const BILLING_PERIOD_BREAKPOINT_DAY = '15'; // 15th of the month
export const BILLING_PERIOD_DUE_DAY = 5; // 5th of the month
export const BILLING_PERIOD_WARNING_DAY = 1; // 1st of the month
export const ABSENCE_DUE_DAY = 5; // 5th of the month
export const REPORTING_WARNING_DAY_FROM_END_OF_MONTH = 2; // 2 days from the end of the month

//ANCHOR Permission ------------------------------------------------------------
export const PERMISSION = {
  // Administrate
  ADMINISTRATE: {
    USER: {
      LIST_NEW_EDIT: [
        'SUPER_ADMIN',
        // 'BOARD_OF_DIRECTORS',
        // 'TEAM_LEAD',
        // 'CHANNEL_TEAM_LEAD',
        'ADMIN',
        // 'ECONOMY',
        // 'HUMAN_RESOURCES',
        // 'CLIENT_MANAGER',
        // 'CONSULTANT',
        // 'LIA',
        // 'SUB_CONSULTANT',
      ],
    },
  },

  // Client
  CLIENT: {
    ALL_CLIENTS_CM_PAGES: [
      'SUPER_ADMIN',
      'BOARD_OF_DIRECTORS',
      'TEAM_LEAD',
      // 'CHANNEL_TEAM_LEAD',
      // 'ADMIN',
      'ECONOMY',
      // 'HUMAN_RESOURCES',
      // 'CLIENT_MANAGER',
      // 'CONSULTANT',
      // 'LIA',
      // 'SUB_CONSULTANT',
    ],
    ADD: [
      'SUPER_ADMIN',
      'BOARD_OF_DIRECTORS',
      'TEAM_LEAD',
      // 'CHANNEL_TEAM_LEAD',
      'ADMIN',
      'ECONOMY',
      // 'HUMAN_RESOURCES',
      // 'CLIENT_MANAGER',
      // 'CONSULTANT',
      // 'LIA',
      // 'SUB_CONSULTANT',
    ],
    EDIT_ACCOUNTING_DATA: [
      'SUPER_ADMIN',
      'BOARD_OF_DIRECTORS',
      'TEAM_LEAD',
      // 'CHANNEL_TEAM_LEAD',
      'ADMIN',
      'ECONOMY',
      // 'HUMAN_RESOURCES',
      'CLIENT_MANAGER',
      // 'CONSULTANT',
      // 'LIA',
      // 'SUB_CONSULTANT',
    ],
  },

  // Team
  TEAM: {
    FULL_ACCESS: ['SUPER_ADMIN'],
  },

  // Invoice
  INVOICE: {
    ALL_INVOICES: [
      'SUPER_ADMIN',
      'BOARD_OF_DIRECTORS',
      'TEAM_LEAD',
      // 'CHANNEL_TEAM_LEAD',
      // 'ADMIN',
      'ECONOMY',
      // 'HUMAN_RESOURCES',
      // 'CLIENT_MANAGER',
      // 'CONSULTANT',
      // 'LIA',
      // 'SUB_CONSULTANT',
    ],
  },

  // Rootlytics
  ROOTLYTICS: {
    FULL_ACCESS: [
      'SUPER_ADMIN',
      'BOARD_OF_DIRECTORS',
      'TEAM_LEAD',
      'CHANNEL_TEAM_LEAD',
      // 'ADMIN',
      'ECONOMY',
      // 'HUMAN_RESOURCES',
      // 'CLIENT_MANAGER',
      // 'CONSULTANT',
      // 'LIA',
      // 'SUB_CONSULTANT',
    ],
  },
};
