// src/redux/slices/rootlytics.js

// IMPORTS
// Core
import { createSlice } from '@reduxjs/toolkit';
// Utilities
import axios from 'src/utils/axios';
import { createSelector } from 'reselect';
import { extractParamsFromCacheKey, checkIsTeamReport } from 'src/sections/@dashboard/rootlytics/rootlyticsUtils';

// CONFIGURATION ---------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: null,
  data: {},
};

// ⭐️ MAIN SLICE ⭐️ ************************************************************
const rootlyticsSlice = createSlice({
  name: 'rootlytics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA SUCCESS
    getDataSuccess(state) {
      state.isLoading = false;
    },

    // STORE DATA IN CACHE
    storeData(state, action) {
      const { cacheKey, data } = action.payload;
      state.data[cacheKey] = data;
    },

    // RESET DATA
    resetData(state, action) {
      const { viewType } = action.payload;
      state[viewType] = [];
    },

    // RESET ERROR
    resetError(state) {
      state.error = null;
    },
  },
});

// ACTIONS ---------------------------------------------------------------------
export const { startLoading, hasError, getDataSuccess, storeData, resetData, resetError } = rootlyticsSlice.actions;

// THUNKS ----------------------------------------------------------------------
/**
 * Fetches data based on the cacheKey.
 *
 * @param {Object} params - Contains the cacheKey.
 * @param {string} params.cacheKey - The cache key for storing/retrieving data.
 *
 * @returns {Function} Thunk function to be dispatched by Redux.
 */
export function getRootlytics({ cacheKey }) {
  return async (dispatch, getState) => {
    const cachedData = getState().rootlytics.data[cacheKey];

    if (cachedData) {
      dispatch(resetError());
      dispatch(getDataSuccess({ data: cachedData }));
      return;
    }

    dispatch(startLoading());

    // Extract parameters from the cacheKey for the API request
    const { reportType, reportView, periodType, periodYear, periodMonth, dynamicParams, isClientOverview } =
      extractParamsFromCacheKey(cacheKey);

    // Compute startDate and endDate based on periodType
    let startDate, endDate;
    if (periodType === 'month') {
      console.log('periodType month');
      startDate = `${periodYear}-${periodMonth}-01`;
      // Calculate the last day of the month
      const month = parseInt(periodMonth, 10);
      const date = new Date(periodYear, month, 0); // month is 1-based
      const lastDay = date.getDate();
      endDate = `${periodYear}-${periodMonth}-${lastDay}`;
    } else {
      console.log('periodType year');
      // For 'year' periodType
      startDate = `${periodYear}-01-01`;
      endDate = `${periodYear}-12-31`;
    }

    const isTeamReport = checkIsTeamReport(reportType);

    try {
      let response;
      if (isClientOverview) {
        const params = {
          start_date: startDate,
          end_date: endDate,
        };

        if (isTeamReport) {
          // 🟢 Add parameters for team reports in "Clients Billable" view
          params.type = 'channel_team';
          params.sub_type = 'client';
          params.team_id = dynamicParams.team_id || 0;
        } else {
          // 🟢 Default parameters for organization-level view
          params.type = 'client';
          // No sub_type or team_id
        }

        response = await axios.get(`/agency-revenue/1/client-overview`, { params });
      } else {
        response = await axios.get(`/agency-revenue/1`, {
          params: {
            type: isTeamReport ? reportType : reportView,
            start_date: startDate,
            end_date: endDate,
            ...(dynamicParams && Object.keys(dynamicParams).length ? dynamicParams : {}),
            ...(isTeamReport && reportView ? { sub_type: reportView } : {}),
          },
        });
      }

      dispatch(storeData({ cacheKey, data: response.data.data }));
      dispatch(getDataSuccess({ data: response.data.data }));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Updates the agency revenue period.
 *
 * @param {Object} params - The parameters for updating the period.
 * @param {string} params.period_start - The start of the period.
 * @param {string} params.period_end - The end of the period.
 *
 * @returns {Function} Thunk function to be dispatched by Redux.
 */
export function updateRootlyticsPeriod({ period_start, period_end }) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.put(`/agency-revenue/update`, null, {
        params: { period_start, period_end },
      });
      return response;
    } catch (error) {
      dispatch(hasError(error));
      return error;
    }
  };
}

/**
 * Updates the agency revenue goal.
 *
 * @param {Object} params - The parameters for updating the goal.
 * @param {string} params.id - The ID of the revenue item.
 * @param {number} params.goal_amount - The new goal amount.
 *
 * @returns {Function} Thunk function to be dispatched by Redux.
 */
export function updateRootlyticsGoal({ id, goal_amount }) {
  return async (dispatch, getState) => {
    dispatch(startLoading());

    // Optimistically update the UI
    const state = getState();
    const previousData = state.rootlytics.data;
    const updatedData = {
      ...previousData,
      [id]: {
        ...previousData[id],
        goal_amount,
      },
    };

    dispatch(storeData({ cacheKey: id, data: updatedData }));

    try {
      const response = await axios.put(`/agency-revenue/${id}/update-goal`, null, {
        params: { goal_amount },
      });

      // If the API call succeeds, there's nothing more to do since we've already updated the UI
      return response;
    } catch (error) {
      // If the API call fails, we should revert to the previous state
      dispatch(storeData({ cacheKey: id, data: previousData }));
      dispatch(hasError(error));
      return error;
    }
  };
}

// SELECTORS -------------------------------------------------------------------
const selectRootlyticsState = (state) => state.rootlytics;

/**
 * Selects cached data based on the provided cacheKey.
 *
 * @param {string} cacheKey - The cache key.
 *
 * @returns {Function} Selector function for retrieving cached data.
 */
export const selectCachedData = (cacheKey) => {
  return (state) => {
    return state.rootlytics.data[cacheKey];
  };
};

/**
 * Selects the loading state of the rootlytics slice.
 *
 * @returns {boolean} True if data is being loaded, false otherwise.
 */
export const selectIsLoading = createSelector([selectRootlyticsState], (rootlytics) => rootlytics.isLoading);

/**
 * Selects the error state of the rootlytics slice.
 *
 * @returns {Object|null} The error object if an error occurred, null otherwise.
 */
export const selectError = createSelector([selectRootlyticsState], (rootlytics) => rootlytics.error);

// EXPORTS ---------------------------------------------------------------------
export default rootlyticsSlice.reducer;
