import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
// layouts
// import MainLayout from '../layouts/main';
// import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,

  // Users stuff
  ReportUserPage,

  // Dashboard: General
  GeneralAppPage,

  // Consultant
  ConsultantListPage,
  ConsultantCreatePage,
  ConsultantEditPage,
  ConsultantCardsPage,
  ConsultantProfilePage,

  // Dashboard: client
  ClientListPage,
  ClientCreatePage,
  ClientAccountPage,
  ClientEditPage,
  ClientProfilePage,

  // Teams
  TeamCreatePage,
  TeamListPage,
  TeamProfilePage,
  TeamEditPage,

  // Channels
  ChannelListPage,
  ChannelEditPage,
  ChannelCreatePage,

  // Timelog
  TimeLogListPage,
  TimeRecordListPage,

  // Partners
  PartnerListPage,
  PartnerLicensesListPage,

  // License
  LicenseListPage,
  LicenseEditPage,
  LicenseCreatePage,

  // Dashboard: App
  MonthlyReports,

  // Dashboard: User
  UserListPage,
  UserEditPage,
  // UserCardsPage,
  UserCreatePage,
  // UserProfilePage,
  UserAccountPage,

  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,

  // Dashboard: Media Account
  MediaFinancialPage,

  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogPostEditPage,
  BlogNewPostPage,

  // Dashboard: FileManager
  FileManagerPage,
  SingleFilePage,

  // What's new
  WhatsNewPage,

  //
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  PartnerEditPage,
  PartnerCreatePage,
  RootlyticsPage,
} from './elements';
import { PATH_DASHBOARD } from './paths';
import useRouteLayout from 'src/hooks/useRouteLayout';

// COMPONENT DEFINITION --------------------------------------------------------
export default function Router() {
  // useRouteLayout();

  return useRoutes([
    // User must login
    {
      element: <Navigate replace to="/auth/login" />,
      children: [
        { element: <Navigate replace to="/auth/login" />, index: true },
        { path: 'login', element: <LoginPage /> },
      ],
    },
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              {' '}
              <LoginPage />{' '}
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    // Dashboard
    {
      path: PATH_DASHBOARD.root, // 'beet'
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate replace to={PATH_AFTER_LOGIN} />, index: true },
        {
          path: 'home',
          element: (
            <RoleBasedGuard
              roles={[
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                'TEAM_LEAD',
                'CHANNEL_TEAM_LEAD',
                'ADMIN',
                'ECONOMY',
                'HUMAN_RESOURCES',
                'CONSULTANT',
                'LIA',
                'SUB_CONSULTANT',
              ]}
              hasContent
            >
              <GeneralAppPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'report',
          element: (
            <RoleBasedGuard
              roles={[
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                'TEAM_LEAD',
                'CHANNEL_TEAM_LEAD',
                'ADMIN',
                'ECONOMY',
                'HUMAN_RESOURCES',
                'CLIENT_MANAGER',
                'CONSULTANT',
                // 'LIA',
                // 'SUB_CONSULTANT',
              ]}
              hasContent
            >
              <ReportUserPage />{' '}
            </RoleBasedGuard>
          ),
        },
        {
          path: 'clients',
          element: (
            <RoleBasedGuard
              roles={[
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                'TEAM_LEAD',
                'CHANNEL_TEAM_LEAD',
                'ADMIN',
                'ECONOMY',
                'HUMAN_RESOURCES',
                'CLIENT_MANAGER',
                'CONSULTANT',
                // 'LIA',
                // 'SUB_CONSULTANT',
              ]}
              hasContent
            >
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            { element: <Navigate replace to="list" />, index: true },
            { path: ':id/profile', element: <ClientProfilePage /> },
            { path: 'list', element: <ClientListPage /> },
            {
              path: 'new',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    // 'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    // 'ADMIN',
                    // 'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                  hasContent
                >
                  <ClientCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    // 'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    // 'ADMIN',
                    // 'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                  hasContent
                >
                  <ClientEditPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/account',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    // 'ADMIN',
                    'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                  hasContent
                >
                  {' '}
                  <ClientAccountPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'consultant',
          element: (
            <RoleBasedGuard
              roles={[
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                'TEAM_LEAD',
                'CHANNEL_TEAM_LEAD',
                'ADMIN',
                'ECONOMY',
                'HUMAN_RESOURCES',
                'CLIENT_MANAGER',
                'CONSULTANT',
                'LIA',
                'SUB_CONSULTANT',
              ]}
            >
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            { element: <Navigate replace to="list" />, index: true },
            {
              path: 'list',
              element: <ConsultantCardsPage />,
            },
            {
              path: 'admin',
              element: <ConsultantListPage />,
            },
            {
              path: ':id/profile',
              element: <ConsultantProfilePage />,
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    // 'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    // 'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                  hasContent
                >
                  <ConsultantCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    // 'ECONOMY',
                    'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                  hasContent
                >
                  <ConsultantEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'team',
          children: [
            { element: <Navigate replace to="list" />, index: true },
            { path: 'list', element: <TeamListPage /> },
            { path: ':id/profile', element: <TeamProfilePage /> },
            {
              path: 'new',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    // 'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    // 'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  {' '}
                  <TeamCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    'TEAM_LEAD',
                    'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    // 'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  <TeamEditPage />
                </RoleBasedGuard>
              ),
            },
            { path: ':id/clients', element: <ClientProfilePage /> },
          ],
        },
        {
          path: 'monthly-reports',
          element: (
            <RoleBasedGuard
              hasContent
              roles={[
                // 'SUB_CONSULTANT',
                // 'LIA',
                // 'CONSULTANT',
                'SUPER_ADMIN',
                // 'ADMIN',
                // 'BOARD_OF_DIRECTORS',
                'ECONOMY',
                // 'HUMAN_RESOURCES',
                'TEAM_LEAD',
                // 'CHANNEL_TEAM_LEAD',
                // 'CLIENT_MANAGER',
              ]}
            >
              <MonthlyReports />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'timelog',
          children: [
            {
              element: <Navigate replace to="/records" />,
              index: true,
            },
            {
              path: 'records',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    'TEAM_LEAD',
                    'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    // 'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  <TimeRecordListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'logs',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    'TEAM_LEAD',
                    'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    // 'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  <TimeLogListPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'news',
          element: (
            <RoleBasedGuard
              hasContent
              roles={[
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                'TEAM_LEAD',
                'CHANNEL_TEAM_LEAD',
                'ADMIN',
                'ECONOMY',
                'HUMAN_RESOURCES',
                'CLIENT_MANAGER',
                'CONSULTANT',
                'LIA',
                // 'SUB_CONSULTANT',
              ]}
            >
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            { path: '', element: <BlogPostsPage /> },
            // { path: 'posts', element:  },
            { path: 'post/:id', element: <BlogPostPage /> },
            {
              path: 'new',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    'TEAM_LEAD',
                    'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    'ECONOMY',
                    'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  <BlogNewPostPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'post/:id/edit',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    'TEAM_LEAD',
                    'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    'ECONOMY',
                    'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  <BlogPostEditPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'whats-new',
          element: (
            <RoleBasedGuard
              hasContent
              roles={[
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                'TEAM_LEAD',
                'CHANNEL_TEAM_LEAD',
                'ADMIN',
                'ECONOMY',
                'HUMAN_RESOURCES',
                'CLIENT_MANAGER',
                'CONSULTANT',
                'LIA',
                'SUB_CONSULTANT',
              ]}
            >
              <WhatsNewPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'finances',
          children: [
            { path: '', element: <Navigate replace to="budget" /> },
            {
              path: 'invoice',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    'SUPER_ADMIN',
                    // 'BOARD_OF_DIRECTORS',
                    'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    // 'ADMIN',
                    'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                { path: '', element: <Navigate replace to="list" /> },
                { path: 'list', element: <InvoiceListPage /> },
                { path: ':id', element: <InvoiceDetailsPage /> },
                { path: 'new', element: <InvoiceCreatePage /> },
              ],
            },
            {
              path: 'budget',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    // 'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    // 'ADMIN',
                    'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                { path: '', element: <Navigate replace to="list" /> },
                // { path: 'list', index: true, element: <MediaBudgetsList /> },
                { path: 'list', index: true, element: <MediaFinancialPage /> },
                //{ path: ':id', element: <InvoiceDetailsPage /> },
                //{ path: ':id/edit', element: <InvoiceEditPage /> },
                //{ path: 'new', element: <InvoiceCreatePage /> },
              ],
            },
            {
              path: 'rootlytics',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    'TEAM_LEAD',
                    'CHANNEL_TEAM_LEAD',
                    // 'ADMIN',
                    'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                  hasContent
                >
                  <RootlyticsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'administrate',
          element: (
            <RoleBasedGuard
              hasContent
              roles={[
                'SUPER_ADMIN',
                'BOARD_OF_DIRECTORS',
                // 'TEAM_LEAD',
                // 'CHANNEL_TEAM_LEAD',
                'ADMIN',
                // 'ECONOMY',
                // 'HUMAN_RESOURCES',
                // 'CLIENT_MANAGER',
                // 'CONSULTANT',
                // 'LIA',
                // 'SUB_CONSULTANT',
              ]}
            >
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            { path: '', element: <Navigate replace to="user" /> },
            {
              path: 'user',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    'TEAM_LEAD',
                    'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    'ECONOMY',
                    'HUMAN_RESOURCES',
                    'CLIENT_MANAGER',
                    'CONSULTANT',
                    'LIA',
                    'SUB_CONSULTANT',
                  ]}
                >
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                {
                  path: '',
                  element: <Navigate replace to="list" />,
                },
                {
                  path: 'list',
                  index: true,
                  element: (
                    <RoleBasedGuard
                      roles={[
                        'SUPER_ADMIN',
                        // 'BOARD_OF_DIRECTORS',
                        // 'TEAM_LEAD',
                        // 'CHANNEL_TEAM_LEAD',
                        'ADMIN',
                        // 'ECONOMY',
                        // 'HUMAN_RESOURCES',
                        // 'CLIENT_MANAGER',
                        // 'CONSULTANT',
                        // 'LIA',
                        // 'SUB_CONSULTANT',
                      ]}
                    >
                      <UserListPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'new',
                  element: (
                    <RoleBasedGuard
                      roles={[
                        'SUPER_ADMIN',
                        // 'BOARD_OF_DIRECTORS',
                        // 'TEAM_LEAD',
                        // 'CHANNEL_TEAM_LEAD',
                        'ADMIN',
                        // 'ECONOMY',
                        // 'HUMAN_RESOURCES',
                        // 'CLIENT_MANAGER',
                        // 'CONSULTANT',
                        // 'LIA',
                        // 'SUB_CONSULTANT',
                      ]}
                    >
                      <UserCreatePage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'account',
                  element: (
                    <RoleBasedGuard
                      roles={[
                        'SUPER_ADMIN',
                        'BOARD_OF_DIRECTORS',
                        'TEAM_LEAD',
                        'CHANNEL_TEAM_LEAD',
                        'ADMIN',
                        'ECONOMY',
                        'HUMAN_RESOURCES',
                        'CLIENT_MANAGER',
                        'CONSULTANT',
                        'LIA',
                        'SUB_CONSULTANT',
                      ]}
                    >
                      <UserAccountPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <RoleBasedGuard
                      roles={[
                        'SUPER_ADMIN',
                        // 'BOARD_OF_DIRECTORS',
                        // 'TEAM_LEAD',
                        // 'CHANNEL_TEAM_LEAD',
                        'ADMIN',
                        // 'ECONOMY',
                        // 'HUMAN_RESOURCES',
                        // 'CLIENT_MANAGER',
                        // 'CONSULTANT',
                        // 'LIA',
                        // 'SUB_CONSULTANT',
                      ]}
                    >
                      <UserEditPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'channel',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    // 'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    // 'ADMIN',
                    // 'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                { path: '', element: <Navigate replace to="list" /> },
                { path: 'list', index: true, element: <ChannelListPage /> },
                { path: 'new', element: <ChannelCreatePage /> },
                { path: ':id/edit', element: <ChannelEditPage /> },
              ],
            },
            {
              path: 'partner',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    // 'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    // 'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                { path: '', element: <Navigate replace to="list" /> },
                { path: 'list', index: true, element: <PartnerListPage /> },
                { path: 'licenses', element: <PartnerLicensesListPage /> },
                { path: 'new', element: <PartnerCreatePage /> },
                { path: ':id/edit', element: <PartnerEditPage /> },
              ],
            },
            {
              path: 'license',
              element: (
                <RoleBasedGuard
                  roles={[
                    'SUPER_ADMIN',
                    'BOARD_OF_DIRECTORS',
                    // 'TEAM_LEAD',
                    // 'CHANNEL_TEAM_LEAD',
                    'ADMIN',
                    // 'ECONOMY',
                    // 'HUMAN_RESOURCES',
                    // 'CLIENT_MANAGER',
                    // 'CONSULTANT',
                    // 'LIA',
                    // 'SUB_CONSULTANT',
                  ]}
                >
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                { path: '', element: <Navigate replace to="list" /> },
                { path: 'list', index: true, element: <LicenseListPage /> },
                { path: 'new', element: <LicenseCreatePage /> },
                { path: ':id/edit', element: <LicenseEditPage /> },
              ],
            },
          ],
        },
        {
          path: 'files-manager',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['SUPER_ADMIN']}>
                  <FileManagerPage />
                </RoleBasedGuard>
              ),
              index: true,
            },
            { path: ':title', element: <SingleFilePage /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate replace to="/404" /> },
  ]);
}
